// store/Widget/Widget.reducer.js
import { SET_WIDGET_DATA } from './Widget.action';

const initialState = {};

const widgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WIDGET_DATA:
            return {
                ...state,
                [action.payload.widgetid]: action.payload.data,
            };
        default:
            return state;
    }
};

export default widgetReducer;
