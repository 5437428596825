// store/Widget/Widget.action.js
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA';

export const setWidgetData = (widgetid, data) => ({
    type: SET_WIDGET_DATA,
    payload: {
        widgetid: String(widgetid),  // Convert to string
        data,
    },
});
